<template>
<main id="main">
  <div class="wrap1160">
    <section id="login-form" class="bgLGray">
      <form>
        <table class="tbl-login">
          <tbody>
            <tr>
              <th>
                {{$t("LABEL_LOGINID")}}<em class="req">※</em>
              </th>
              <td>
                <input type="text" v-bind:class="{ 'ime-dis': true, 'err': errors.user_id}" v-model="userId" :placeholder="$t('PLACEHOLDER_USERID')" ref="user_id" required>
                <p class="err-txt">{{errors.user_id}}</p>
              </td>
            </tr>
            <tr>
              <th>
                {{$t("LABEL_PASSWORD")}}<em class="req">※</em>
              </th>
              <td>
                <Password :value="password" @update:value="(val) => password=val" :error="errors.password" refTxt="password"/>
              </td>
            </tr>
            <tr>
              <th class="only_pc">&nbsp;</th>
              <td class="check-idpass">
                <label>
                  <input type="checkbox" v-model="saveLoginInfoFlag" class="checkbox-input">
                  <span class="checkbox-parts space_click_event" tabindex="0">{{$t("LABEL_SAVE_ID_PASSWORD_USERNAME")}}</span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="forget-pass">
          <a @click="$routerGo($define.PAGE_NAMES.REISSUE_PASSWORD_PAGE_NAME)">{{$t("LINK_FORGOT_PASSWORD")}}</a>
        </div>
        <div class="id-pass-err">
          <p class="err-txt">{{errors.message}}</p>
        </div>
        <div class="btn-form">
          <input type="button" id="sbm-login" :value="$t('PAGE_LOGIN')" @click="login" tabindex="0">
        </div>
      </form>
      <div class="request">
        <a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t("LINK_NEW_MEMBER_REGISTRATION_APPLICATION_FREE")}}</a>
      </div>
    </section>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods'
import Password from '@/components/Login/Password'
import useLoading from '@/stores/useLoading'
import {Icon} from '@iconify/vue2'
import {defineComponent} from '@vue/composition-api'
// Import {useCookies} from '@vueuse/integrations/useCookies'
import CryptoJS from 'crypto-js'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    // Const cookies = useCookies()
    return {
      loadingStore,
      // Cookies
    }
  },
  components : {
    Icon,
    Password
  },
  data() {
    return {
      userId            : localStorage[this.$define.LOCAL_STORE_LABEL.STORAGE_USER_ID],
      password          : '',
      errors            : '',
      saveLoginInfoFlag : false,
    }
  },
  methods : {
    login() {
      const loginData = {
        user_id  : this.userId,
        password : this.password
      }
      this.loadingStore.setLoading(true)
      Methods.apiExcute('login', this.$i18n.locale, loginData).then(data => {
        console.log('login success')
        this.$cookies.set(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN, data.token, '0', null, null, Methods.boolHttpsProtocol())
        this.$cookies.set(this.$define.LOCAL_STORE_LABEL.MEMBER_NO, data.member_no, '0', null, null, Methods.boolHttpsProtocol())
        this.loadingStore.setLoading(false)
        if (this.saveLoginInfoFlag) {
          localStorage[process.env.VUE_APP_LOCALSTORAGE_LOGIN_INFO_LABEL] = this.aesEncrypt(loginData)
        } else {
          localStorage.removeItem(process.env.VUE_APP_LOCALSTORAGE_LOGIN_INFO_LABEL)
        }
        if (this.$route.query.next) {
          this.$routerGo(this.$route.query.next, this.$route.query.id, {filter : this.$route.query.filter})
        } else {
          this.$routerGo(this.$define.PAGE_NAMES.TOP_PAGE_NAME)
        }
      })
        .catch(error => {
          console.log('login error: ', error)
          console.log('error.response.data: ', error.response.data)
          this.loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    aesEncrypt(object) {
      const cipher = CryptoJS.AES.encrypt(JSON.stringify(object), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_KEY), {
        iv   : CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_IV),
        mode : CryptoJS.mode.CBC
      })

      return cipher.toString()
    },
    aesDecrypt(txt) {
      const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_KEY), {
        iv   : CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_IV),
        mode : CryptoJS.mode.CBC
      })

      return JSON.parse(CryptoJS.enc.Utf8.stringify(cipher).toString())
    },
  },
  mounted() {
    // Methods.addExternalScripts(this.scripts)
  },
  created() {
    if (localStorage[process.env.VUE_APP_LOCALSTORAGE_LOGIN_INFO_LABEL]) {
      const loginData = this.aesDecrypt(localStorage[process.env.VUE_APP_LOCALSTORAGE_LOGIN_INFO_LABEL])
      if (loginData && loginData.user_id && loginData.password) {
        this.userId = loginData.user_id
        this.password = loginData.password
        this.saveLoginInfoFlag = true
      }
    }
  },
  watch : {
    '$i18n.locale'() {
      // This.getParticipationTerms()
    },
    errors(value) {
      const keys = Object.keys(value)
      if (keys.length > 0 && this.$refs[keys[0]]) {
        this.$refs[keys[0]].focus()
      }
    }
  }
})
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
